import { getApolloClient } from '@/apollo-client'
import { getLatestSupplyOptimizationQuery } from '@/services/graphql/queries'
import { onStockStore } from '../onstock'
import { SupplyOptimization } from '../supply'
import {
  createDefaultLengthRequest,
  createDefaultWasteRequest,
  createSupplyOptimizationRequest,
  exportSupplyOptimizationRequest,
  removeDefaultLengthRequest,
  updateDefaultWasteRequest,
} from './requests'
import {
  decreaseSupplyProgress,
  increaseSupplyProgress,
  setSupplyDefaultLengths,
  setSupplyDefaultWaste,
  setSupplyError,
  supplyStore,
} from './supply.store'
import { SupplyDefaultLength, SupplyPortionInfo, SupplySummaryLength } from './types'
import { SupplyDefaultWaste } from './types/SupplyDefaultWaste.model'

export const createDefaultLength = async (
  projectId: string,
  modelId: string,
  length: number
): Promise<SupplyDefaultLength | undefined> => {
  increaseSupplyProgress()

  try {
    const result = await createDefaultLengthRequest(projectId, modelId, length)

    if (result && supplyStore.defaultLengths) {
      setSupplyDefaultLengths([...supplyStore.defaultLengths, result])
    }

    decreaseSupplyProgress()
    return result
  } catch (error: any) {
    console.error({ error })
    setSupplyError((error as Error).message)
  }

  decreaseSupplyProgress()
}

export const createDefaultWaste = async (
  projectId: string,
  modelId: string,
  waste: number
): Promise<SupplyDefaultWaste | undefined> => {
  increaseSupplyProgress()

  try {
    const result = await createDefaultWasteRequest(projectId, modelId, waste)

    setSupplyDefaultWaste(result || null)

    decreaseSupplyProgress()
    return result
  } catch (error: any) {
    console.error({ error })
    setSupplyError((error as Error).message)
  }

  decreaseSupplyProgress()
}

export const removeDefaultLength = async (
  projectId: string,
  modelId: string,
  defaultLengthId: number
): Promise<boolean | undefined> => {
  increaseSupplyProgress()

  try {
    const result = await removeDefaultLengthRequest(projectId, modelId, defaultLengthId.toString())

    if (result && supplyStore.defaultLengths) {
      setSupplyDefaultLengths(
        supplyStore.defaultLengths.filter((defaultLength) => defaultLength.id !== defaultLengthId)
      )
    }

    decreaseSupplyProgress()
    return true
  } catch (error: any) {
    console.error({ error })
    setSupplyError((error as Error).message)
  }

  decreaseSupplyProgress()
}

export const updateDefaultWaste = async (
  projectId: string,
  modelId: string,
  waste: number
): Promise<SupplyDefaultWaste | undefined> => {
  increaseSupplyProgress()

  try {
    const result = await updateDefaultWasteRequest(projectId, modelId, waste)

    setSupplyDefaultWaste(result || null)

    decreaseSupplyProgress()
    return result
  } catch (error: any) {
    console.error({ error })
    setSupplyError((error as Error).message)
  }

  decreaseSupplyProgress()
}

export const createSupplyOptimization = async (
  projectId: string,
  modelId: string,
  modelFile: File,
  portions: SupplyPortionInfo[],
  supplySummaryLegths: SupplySummaryLength[],
  tolerance: number
): Promise<SupplyOptimization | undefined> => {
  increaseSupplyProgress()

  try {
    const supplyOptimization = await createSupplyOptimizationRequest(
      projectId,
      modelId,
      modelFile,
      portions,
      supplySummaryLegths,
      tolerance
    )

    decreaseSupplyProgress()
    return supplyOptimization
  } catch (error: any) {
    console.error({ error })
    decreaseSupplyProgress()
    setSupplyError((error as Error).message)
  }
}

export async function getLatestSupplyOptimization(): Promise<SupplyOptimization> {
  increaseSupplyProgress()

  const client = await getApolloClient()
  const response = await client.query({
    query: getLatestSupplyOptimizationQuery(onStockStore.onStockModel?.steelspaceId || ''),
  })

  decreaseSupplyProgress()

  return response.data.modelSupplyOptimizations.nodes[0]
}

export async function getSupplyOptimization(
  steelspaceHistoryItemId?: string
): Promise<SupplyOptimization | undefined> {
  increaseSupplyProgress()

  if (!onStockStore.project) {
    decreaseSupplyProgress()
    return undefined
  }

  if (steelspaceHistoryItemId) {
    decreaseSupplyProgress()
    return onStockStore.onStockModel?.supplyOptimizations.find(
      (supplyOpt) => supplyOpt.historyItem?.steelspaceId == steelspaceHistoryItemId
    )
  }

  if (onStockStore.onStockModel?.supplyOptimizations) {
    const latestSupplyOptWithoutHistory = onStockStore.onStockModel?.supplyOptimizations.find(
      (supplyOpt) => !supplyOpt.historyItem
    )

    if (latestSupplyOptWithoutHistory) {
      decreaseSupplyProgress()
      return latestSupplyOptWithoutHistory
    }
  }

  decreaseSupplyProgress()
  return undefined
}

export const exportSupplyOptimization = async (
  projectId: string,
  modelId: string,
  supplyOptimizationId: string
): Promise<void> => {
  increaseSupplyProgress()

  await exportSupplyOptimizationRequest(projectId, modelId, supplyOptimizationId)
  decreaseSupplyProgress()
}
