import { Color3 } from '@consteel/straw'
import Vue from 'vue'
import { WarehouseOptimization } from '../graphql/graphql.types'

const state = Vue.observable({
  warehouseInstanceColors: [] as { id: string; color: Color3 }[],
  warehouseOptimization: null as WarehouseOptimization | null,
  warehouseOptimizationSecondary: null as WarehouseOptimization | null,
  progress: 0,
  error: '',
})

export const increaseWarehouseProgress = (): void => {
  state.progress += 1
}

export const decreaseWarehouseProgress = (): void => {
  state.progress -= 1
}

export const getWarehouseLoading = (): boolean => {
  return state.progress !== 0
}

export const setWarehouseInstanceColors = (value: { id: string; color: Color3 }[]): void => {
  state.warehouseInstanceColors = value
}

export const setWarehouseOptimization = (value: WarehouseOptimization | null): void => {
  state.warehouseOptimization = value
}

export const setWarehouseOptimizationSecondary = (value: WarehouseOptimization | null): void => {
  state.warehouseOptimizationSecondary = value
}

export const setWarehouseError = (value: string): void => {
  state.error = value
}

export const warehouseStore = state as Readonly<typeof state>
