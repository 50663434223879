import { WarehouseOptimizationTreeNode } from '@/components/Viewer/WarehouseOptimizationSelectTree.vue'
import { getCompareStateBasedOnFadingLevel, ModelStateNumber } from '@/store/modelViewer.store'
import { Model } from '@consteel/stoke/src/ObjectDataBuilders/SmadsteelObjectBuilder'
import { Get, StructuralMember } from '@consteel/storm'
import { guid } from '@consteel/storm/src/Models/ModelBase'
import { getApolloSdk } from '../graphql/apollo.client'
import { SummaryType, WarehouseOptimization } from '../graphql/graphql.types'
import { onStockStore } from '../onstock/onstock.store'
import { OnStockPortionInfo } from '../onstock/types/OnStockSupplyOptimization/OnStockPortionInfo.model'
import {
  createWarehouseOptimizationRequest,
  deleteObjectMatchesBasedOnSummaryItemRequest,
  deleteObjectMatchRequest,
  deleteWarehouseObjectsByMatchedWarehouseObjectRequest,
  exportWarehouseOptimizationRequest,
} from './requests'
import {
  decreaseWarehouseProgress,
  increaseWarehouseProgress,
  setWarehouseError,
  setWarehouseOptimization,
  warehouseStore,
} from './warehouse.store'

export async function getWarehouseOptimization(
  steelspaceHistoryItemId?: string
): Promise<WarehouseOptimization | undefined> {
  increaseWarehouseProgress()
  if (!onStockStore.project) {
    decreaseWarehouseProgress()
    return undefined
  }

  if (steelspaceHistoryItemId) {
    decreaseWarehouseProgress()
    return onStockStore.onStockModel?.warehouseOptimizations.find(
      (corr) => corr.historyItem?.steelspaceId == steelspaceHistoryItemId
    )
  }

  if (onStockStore.onStockModel?.warehouseOptimizations) {
    decreaseWarehouseProgress()
    return await getLatestWarehouseOptimization()
  }
  decreaseWarehouseProgress()
  return undefined
}

export function compareWarehouseOptimization(): string[] {
  increaseWarehouseProgress()

  const changedIds = [] as guid[]

  warehouseStore.warehouseOptimization?.warehouseObjectMatches.forEach((objectMatch) => {
    const matchedObjectMatch =
      warehouseStore.warehouseOptimizationSecondary?.warehouseObjectMatches.find(
        (objectMatchSecondary) => {
          return objectMatchSecondary.smadsteelId === objectMatch.smadsteelId
        }
      )

    if (!matchedObjectMatch) changedIds.push(objectMatch.smadsteelId)
  })

  warehouseStore.warehouseOptimizationSecondary?.warehouseObjectMatches.forEach((objectMatch) => {
    const matchedObjectMatch = warehouseStore.warehouseOptimization?.warehouseObjectMatches.find(
      (objectMatchSecondary) => {
        return objectMatchSecondary.smadsteelId === objectMatch.smadsteelId
      }
    )

    if (!matchedObjectMatch && !changedIds.includes(objectMatch.smadsteelId))
      changedIds.push(objectMatch.smadsteelId)
  })

  decreaseWarehouseProgress()
  return changedIds
}

export async function getLatestWarehouseOptimization(): Promise<WarehouseOptimization | undefined> {
  increaseWarehouseProgress()

  const response = await getApolloSdk().getLatestWarehouseOptimizationQuery({
    steelspaceId: onStockStore.onStockModel?.steelspaceId || '',
  })

  decreaseWarehouseProgress()

  return response.modelWarehouseOptimizations?.nodes?.[0]
}

export const createWarehouseOptimization = async (
  projectId: string,
  modelId: string,
  warehouseCatalog: File,
  modelFile: File,
  tolerance: number,
  method: number,
  mainPriority: number,
  secondaryPriority: number,
  mainPriorityOrder: number,
  secondaryPriorityOrder: number,
  managerRuleIds: number[],
  preferExactMatchesChecked: boolean,
  portions: OnStockPortionInfo[]
): Promise<WarehouseOptimization | undefined> => {
  increaseWarehouseProgress()

  try {
    const warehouseOptimization = await createWarehouseOptimizationRequest(
      projectId,
      modelId,
      warehouseCatalog,
      modelFile,
      tolerance,
      method,
      mainPriority,
      secondaryPriority,
      mainPriorityOrder,
      secondaryPriorityOrder,
      managerRuleIds,
      preferExactMatchesChecked,
      portions
    )

    decreaseWarehouseProgress()
    return warehouseOptimization
  } catch (error: any) {
    console.error({ error })
    decreaseWarehouseProgress()
    setWarehouseError((error as Error).message)
  }
}

export const exportWarehouseOptimization = async (
  projectId: string,
  modelId: string,
  warehouseOptimizationId: string
): Promise<void> => {
  increaseWarehouseProgress()

  await exportWarehouseOptimizationRequest(projectId, modelId, warehouseOptimizationId)
  decreaseWarehouseProgress()
}

export const getWarehouseOptimizationForHistoryItem = async (
  modelId: string,
  historyItemId: string
): Promise<WarehouseOptimization | undefined> => {
  increaseWarehouseProgress()

  const resp = await getApolloSdk().getWarehouseOptimizationForHistoryItemQuery({
    steelspaceId: modelId,
    firstModelSteelspaceId: historyItemId,
  })

  decreaseWarehouseProgress()

  return resp.modelWarehouseOptimizations?.nodes?.[0]
}

export const buildWarehouseOptimizationTree = (
  warehouseOptimization: WarehouseOptimization,
  modelFile: Model,
  sectionMap: Map<string, WarehouseOptimizationTreeNode>,
  filter: SummaryType,
  isCompare: boolean,
  modelState?: ModelStateNumber,
  portionId?: string | null,
  hidden = false
): Map<string, WarehouseOptimizationTreeNode> => {
  increaseWarehouseProgress()

  const _sectionMap = sectionMap

  for (const warehouseSummaryItem of warehouseOptimization.warehouseSummaryItems) {
    if (
      (filter === SummaryType.Section && warehouseSummaryItem.type === SummaryType.Section) ||
      (filter === SummaryType.Material && warehouseSummaryItem.type === SummaryType.Material)
    ) {
      const warehouseSummaryItemChild: WarehouseOptimizationTreeNode = {
        id: warehouseSummaryItem.id.toString() ?? 'Other',
        name: warehouseSummaryItem.name ?? 'N/A',
        children: [],
        quantity: warehouseSummaryItem.quantity,
        value: warehouseSummaryItem.length,
        warehouseSummaryItemBlocks: warehouseSummaryItem.warehouseSummaryItemBlocks,
        compare: isCompare,
        modelState,
        objectMatchId: warehouseSummaryItem.id,
        deleteMatchHandler: deleteObjectMatchesBasedOnSummaryItemHandler,
      }

      const relevantWarehouseSummaryItem = _sectionMap.get(warehouseSummaryItem.name)

      if (!relevantWarehouseSummaryItem) {
        _sectionMap.set(warehouseSummaryItem.name, warehouseSummaryItemChild)
      } else {
        relevantWarehouseSummaryItem.valueToCompare = warehouseSummaryItem.length
        relevantWarehouseSummaryItem.quantityToCompare = warehouseSummaryItem.quantity

        relevantWarehouseSummaryItem.warehouseSummaryItemBlocksToCompare =
          warehouseSummaryItem.warehouseSummaryItemBlocks

        _sectionMap.set(warehouseSummaryItem.name, relevantWarehouseSummaryItem)
      }
    }
  }

  const objectMatches = portionId
    ? warehouseOptimization.warehouseOptimizationPortions.find(
        (portion) => portion.portionSmadsteelId == portionId
      )?.warehouseObjectMatches
    : warehouseOptimization.warehouseObjectMatches

  if (!objectMatches) return _sectionMap

  for (const objectMatch of objectMatches) {
    const relevantWarehouseSummaryItem =
      filter === SummaryType.Section
        ? warehouseOptimization.warehouseSummaryItems.find(
            (item) => item.name == objectMatch.warehouseObject.sectionName
          )
        : warehouseOptimization.warehouseSummaryItems.find(
            (item) => item.name == objectMatch.warehouseObject.materialName
          )

    if (!relevantWarehouseSummaryItem) {
      console.warn('relevantWarehouseSummaryItem not found')
      return _sectionMap
    }

    const relevantWarehouseItemNode = _sectionMap.get(relevantWarehouseSummaryItem.name)

    const relevantSmadsteelObject = Get<StructuralMember>(
      modelFile,
      objectMatch.smadsteelId,
      StructuralMember
    )

    const objectMatchChild: WarehouseOptimizationTreeNode = {
      id: relevantSmadsteelObject?.id ?? 'Other',
      name: relevantSmadsteelObject?.name ?? 'N/A',
      children: undefined,
      value: (relevantSmadsteelObject?.geometry?.length || 0) * 1000,
      compare: isCompare,
      modelState,
      hidden,
      objectMatchId: objectMatch.id,
      deleteMatchHandler: deleteObjectMatchHandler,
    }

    const barcodeChild: WarehouseOptimizationTreeNode = {
      id: objectMatch.warehouseObject.barcode.toString() ?? 'Other',
      name: objectMatch.warehouseObject.barcode.toString() ?? 'N/A',
      value: objectMatch.warehouseObject.length,
      open: false,
      modelState,
      children: [objectMatchChild],
      compare: isCompare,
      objectMatchId: objectMatch.warehouseObject.id,
      deleteMatchHandler: deleteWarehouseObjectsByMatchedWarehouseObjectHandler,
    }

    if (relevantWarehouseItemNode) {
      const relevantBarcodeNode = relevantWarehouseItemNode.children?.find(
        (item) => item.id.toString() == objectMatch.warehouseObject.barcode.toString()
      )

      if (relevantBarcodeNode) {
        relevantBarcodeNode.children?.push(objectMatchChild)
      } else {
        relevantWarehouseItemNode.children?.push(barcodeChild)
      }
    }
  }

  decreaseWarehouseProgress()

  return _sectionMap
}

export const buildRemainingItemsTree = (
  remainingMembers: StructuralMember[],
  filter: SummaryType
): Map<string, WarehouseOptimizationTreeNode> => {
  increaseWarehouseProgress()

  const remainingOptMap = new Map<string, WarehouseOptimizationTreeNode>()
  const isFilterSection = filter === SummaryType.Section

  for (const member of remainingMembers) {
    const itemId = isFilterSection ? member.section?.id : member.material?.id // todo name for this
    const itemName = isFilterSection ? member.section?.name : member.material?.name

    if (!itemId || !itemName) return new Map<string, WarehouseOptimizationTreeNode>()

    const itemData = {
      id: itemId,
      name: itemName,
      value: 0,
      hideProgress: true,
    } as WarehouseOptimizationTreeNode

    const memberData = {
      id: member.id,
      name: member.name,
      value: 0,
      hideProgress: true,
    } as WarehouseOptimizationTreeNode

    const section = remainingOptMap.get(itemName)

    if (!section) {
      itemData.children = [memberData]
      remainingOptMap.set(itemName, itemData)
    } else {
      const children = section.children || []

      section.children = [...children, memberData]
      remainingOptMap.set(itemName, section)
    }
  }

  decreaseWarehouseProgress()

  return remainingOptMap
}

export const setFetchedWarehouseOptimization = async (
  isCompare: boolean,
  historyId: string
): Promise<void> => {
  increaseWarehouseProgress()

  if (onStockStore.onStockModel && onStockStore.project) {
    if (isCompare) {
      decreaseWarehouseProgress()
      return
    }

    const relevantWarehouseOptimization = await getWarehouseOptimization(historyId)

    setWarehouseOptimization(relevantWarehouseOptimization || null)
  }

  decreaseWarehouseProgress()
}

export const warehouseOptimizationItems = (
  warehouseOptimization: WarehouseOptimization | null,
  warehouseOptimizationSecondary: WarehouseOptimization | null,
  modelFile: Model | null,
  modelFileSecondary: Model | null,
  filter: SummaryType,
  isCompare: boolean,
  portionId?: string | null
): WarehouseOptimizationTreeNode[] => {
  increaseWarehouseProgress()

  let sectionMap = new Map<string, WarehouseOptimizationTreeNode>()

  if (warehouseOptimization && modelFile) {
    sectionMap = buildWarehouseOptimizationTree(
      warehouseOptimization,
      modelFile,
      sectionMap,
      filter,
      isCompare,
      isCompare ? ModelStateNumber.primary : undefined,
      portionId,
      getCompareStateBasedOnFadingLevel() === ModelStateNumber.secondary
    )
  }

  if (isCompare && warehouseOptimizationSecondary && modelFileSecondary) {
    sectionMap = buildWarehouseOptimizationTree(
      warehouseOptimizationSecondary,
      modelFileSecondary,
      sectionMap,
      filter,
      isCompare,
      ModelStateNumber.secondary,
      portionId,
      getCompareStateBasedOnFadingLevel() === ModelStateNumber.primary
    )
  }

  const result = [] as WarehouseOptimizationTreeNode[]
  sectionMap.forEach((value) => {
    result.push(value)
  })

  decreaseWarehouseProgress()

  return result
}

export async function deleteObjectMatchesBasedOnSummaryItem(
  projectId: string,
  modelId: string,
  correspondenceId: string,
  warehouseSummaryItem: string
): Promise<boolean> {
  increaseWarehouseProgress()
  try {
    const result = await deleteObjectMatchesBasedOnSummaryItemRequest(
      projectId,
      modelId,
      correspondenceId,
      warehouseSummaryItem
    )
    const newWarehouseOptimization = await getWarehouseOptimization() //to update state
    if (newWarehouseOptimization) setWarehouseOptimization(newWarehouseOptimization)
    decreaseWarehouseProgress()
    return result
  } catch (error: any) {
    console.error({ error })
    decreaseWarehouseProgress()
    setWarehouseError((error as Error).message)
  }
  return false
}

export async function deleteWarehouseObjectsByMatchedWarehouseObject(
  projectId: string,
  modelId: string,
  correspondenceId: string,
  matchedWarehouseObjectId: string
): Promise<boolean> {
  increaseWarehouseProgress()
  try {
    const result = await deleteWarehouseObjectsByMatchedWarehouseObjectRequest(
      projectId,
      modelId,
      correspondenceId,
      matchedWarehouseObjectId
    )
    const newWarehouseOptimization = await getWarehouseOptimization() //to update state
    if (newWarehouseOptimization) setWarehouseOptimization(newWarehouseOptimization)
    decreaseWarehouseProgress()
    return result
  } catch (error: any) {
    console.error({ error })
    decreaseWarehouseProgress()
    setWarehouseError((error as Error).message)
  }
  return false
}

export async function deleteObjectMatch(
  projectId: string,
  modelId: string,
  correspondenceId: string,
  objectMatchId: string
): Promise<boolean> {
  increaseWarehouseProgress()
  try {
    const result = await deleteObjectMatchRequest(
      projectId,
      modelId,
      correspondenceId,
      objectMatchId
    )
    const newWarehouseOptimization = await getWarehouseOptimization() //to update state
    if (newWarehouseOptimization) setWarehouseOptimization(newWarehouseOptimization)
    decreaseWarehouseProgress()
    return result
  } catch (error: any) {
    console.error({ error })
    decreaseWarehouseProgress()
    setWarehouseError((error as Error).message)
  }
  return false
}

export const warehouseOptColor = (): string => {
  return '#16A1F0'
}

const deleteObjectMatchesBasedOnSummaryItemHandler = async (id: number): Promise<void> => {
  if (
    !warehouseStore.warehouseOptimization ||
    !onStockStore.project ||
    !onStockStore.onStockModel
  ) {
    console.error(
      'There is no current warehouse optimization to deleting matches based on summary item'
    )
    return
  }

  await deleteObjectMatchesBasedOnSummaryItem(
    onStockStore.project.id.toString(),
    onStockStore.onStockModel.id.toString(),
    warehouseStore.warehouseOptimization.id.toString(),
    id.toString()
  )
}

const deleteWarehouseObjectsByMatchedWarehouseObjectHandler = async (id: number): Promise<void> => {
  if (
    !warehouseStore.warehouseOptimization ||
    !onStockStore.project ||
    !onStockStore.onStockModel
  ) {
    console.error(
      'There is no current correspondence to deleting matches by matched warehouse object'
    )
    return
  }

  await deleteWarehouseObjectsByMatchedWarehouseObject(
    onStockStore.project.id.toString(),
    onStockStore.onStockModel.id.toString(),
    warehouseStore.warehouseOptimization.id.toString(),
    id.toString()
  )
}

const deleteObjectMatchHandler = async (id: number): Promise<void> => {
  if (
    !warehouseStore.warehouseOptimization ||
    !onStockStore.project ||
    !onStockStore.onStockModel
  ) {
    console.error('There is no current correspondence to deleting match')
    return
  }

  await deleteObjectMatch(
    onStockStore.project.id.toString(),
    onStockStore.onStockModel.id.toString(),
    warehouseStore.warehouseOptimization.id.toString(),
    id.toString()
  )
}
