var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "cs-simple-dialog",
    {
      attrs: {
        value: _vm.value,
        title: _vm.$t("Create warehouse optimization rule"),
        persistent: "",
        maxWidth: _vm.maxWidth,
      },
      on: { input: _vm.closeBtnClicked },
    },
    [
      _c(
        "cs-flex",
        {
          staticClass: "manager-rules-dialog-name mb-6",
          attrs: { align: "start" },
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("Name")) + " "),
          _c("cs-text-field", {
            staticClass: "manager-rules-dialog-name-text-field ma-0",
            attrs: {
              dense: "",
              "single-line": "",
              rules: _vm.textFieldRules,
              disabled: _vm.isDialogDisabled,
            },
            model: {
              value: _vm.nameTextField,
              callback: function ($$v) {
                _vm.nameTextField = $$v
              },
              expression: "nameTextField",
            },
          }),
        ],
        1
      ),
      _c(
        "cs-flex",
        {
          staticClass: "manager-rules-dialog-settings mb-3",
          attrs: { justify: "left" },
        },
        [
          _vm._v(_vm._s(_vm.$t("Warehouse optimization settings")) + " "),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "manager-rules-dialog-settings-btn",
                              attrs: { icon: "", small: "" },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_c("info-svg")],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "Szelvénytípus: a választott értékkel erősebb/gyengébb szelvény is megfeleltetésre kerülhet"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "Magasság: a választott értékkel nagyobb/kisebb szelvény is megfeleltetésre kerülhet"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "Szélesség: a választott értékkel nagyobb/kisebb szelvény is megfeleltetésre kerülhet"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "Átmérő: a választott értékkel nagyobb/kisebb szelvény is megfeleltetésre kerülhet"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "mb-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "Anyagminőség: a választott értékkel a folyáshatár szempontjából erősebb anyagminőségű szelvény is megfeleltetésre kerülhet"
                      )
                    ) +
                    " "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _c(
        "cs-flex",
        { staticClass: "mb-3", attrs: { gap: "1rem" } },
        [
          _vm._v(_vm._s(_vm.$t("Section family")) + " "),
          _c(
            "v-menu",
            {
              attrs: {
                "close-on-content-click": false,
                "max-height": _vm.dropDownMenuHight,
                bottom: "",
                "content-class": "manager-rules-dialog-selector",
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on: menu, attrs }) {
                    return [
                      _c(
                        "v-select",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                flat: "",
                                "menu-props": { value: false },
                                "item-text": "text",
                                "item-value": "value",
                                items: [
                                  {
                                    text: _vm.saveSelectedSectionType.text,
                                    value: 0,
                                  },
                                ],
                                disabled: _vm.isDialogDisabled,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "d-flex justify-center manager-rules-dialog-selector-slot",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.text) + " "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.selectedSectionTypeValue,
                                callback: function ($$v) {
                                  _vm.selectedSectionTypeValue = $$v
                                },
                                expression: "selectedSectionTypeValue",
                              },
                            },
                            "v-select",
                            attrs,
                            false
                          ),
                          { ...menu }
                        )
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "cs-flex",
                { staticClass: "manager-rules-dialog-selector-tree-cont" },
                [
                  _c("cs-select-tree", {
                    staticClass: "manager-rules-dialog-selector-tree",
                    attrs: {
                      items: _vm.items,
                      selectedIds: _vm.selectedIds,
                      singleSelect: "",
                    },
                    on: {
                      select: _vm.select,
                      remove: _vm.remove,
                      open: _vm.open,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.isSectionFamilySelected
        ? _c("div", {
            staticClass: "manager-rules-dialog-parameters-placeholder mb-7",
          })
        : [
            _vm.parameterSettings.length === 2
              ? _c(
                  "cs-flex",
                  {
                    staticClass: "mb-7",
                    attrs: { vertical: "", align: "start" },
                  },
                  [
                    _vm._l(
                      _vm.parameterSettings,
                      function (parameterSetting, index) {
                        return [
                          _c("manager-rules-parameter", {
                            key: index,
                            attrs: {
                              parameterName: parameterSetting.name,
                              parameterSelectorDisabled:
                                !parameterSetting.active,
                              selectorStep: parameterSetting.step,
                              selectorDirection: parameterSetting.direction,
                              disableParameter: _vm.isDialogDisabled,
                            },
                            on: {
                              selectorChanged: _vm.parameterSelectorChanged,
                              checkboxChanged: _vm.parameterCheckboxChanged,
                            },
                          }),
                        ]
                      }
                    ),
                  ],
                  2
                )
              : _c(
                  "cs-grid",
                  {
                    staticClass: "mb-7",
                    attrs: { cols: 2, justify: "start", equalCols: "" },
                  },
                  [
                    _vm._l(
                      _vm.parameterSettings,
                      function (parameterSetting, index) {
                        return [
                          _c("manager-rules-parameter", {
                            key: index,
                            attrs: {
                              parameterName: parameterSetting.name,
                              selectorStep: parameterSetting.step,
                              selectorDirection: parameterSetting.direction,
                              parameterSelectorDisabled:
                                !parameterSetting.active,
                              disableParameter: _vm.isDialogDisabled,
                            },
                            on: {
                              selectorChanged: _vm.parameterSelectorChanged,
                              checkboxChanged: _vm.parameterCheckboxChanged,
                            },
                          }),
                        ]
                      }
                    ),
                  ],
                  2
                ),
          ],
      _c("cs-flex", { staticClass: "mb-3", attrs: { justify: "left" } }, [
        _vm._v(_vm._s(_vm.$t("Rule preview")) + " "),
      ]),
      _c(
        "cs-flex",
        { staticClass: "manager-rules-dialog-table-btns-cont" },
        [
          _c(
            "cs-flex",
            {
              staticClass: "manager-rules-dialog-table",
              attrs: { vertical: "", align: "start" },
            },
            [
              _c(
                "cs-btn",
                {
                  class: [
                    "manager-rules-dialog-table-btns",
                    this.isSectionSelected
                      ? ""
                      : "manager-rules-dialog-table-btns-disabled",
                    "mb-3",
                  ],
                  attrs: { outlined: "", disabled: _vm.isSectionDisabled },
                  on: {
                    click: function ($event) {
                      return _vm.setSelectorBtnOnClick(
                        _vm.SelectorOptions.Section
                      )
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Section")))]
              ),
              _c("manager-rules-preview-table", {
                attrs: {
                  sections: _vm.getTableData,
                  tableTitle: _vm.saveSelectedSectionType.text,
                  selectedFamilyName: _vm.saveSelectedSectionType.text,
                  isMaterialSelected: _vm.isMaterialSelected,
                  tableDisabled: _vm.dialogLoading || _vm.tableLoading,
                },
                on: { checkboxChanged: _vm.tableCheckboxChanged },
              }),
            ],
            1
          ),
          _c(
            "cs-flex",
            {
              staticClass: "manager-rules-dialog-table",
              attrs: { vertical: "", align: "start" },
            },
            [
              _c(
                "cs-btn",
                {
                  class: [
                    "manager-rules-dialog-table-btns",
                    this.isMaterialSelected
                      ? ""
                      : "manager-rules-dialog-table-btns-disabled",
                    "mb-3",
                  ],
                  attrs: { outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.setSelectorBtnOnClick(
                        _vm.SelectorOptions.Material
                      )
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Material")))]
              ),
              _c("manager-rules-preview-table", {
                attrs: {
                  sections: _vm.getTableData,
                  tableTitle: _vm.$t("Possible matches"),
                  selectedFamilyName: _vm.saveSelectedSectionType.text,
                  isTableReadOnly: true,
                  isMaterialSelected: _vm.isMaterialSelected,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "cs-flex",
        { staticClass: "mt-7 manager-rules-dialog-main-btns" },
        [
          _c(
            "cs-btn",
            {
              class: [
                _vm.isDialogReadOnly
                  ? "manager-rules-dialog-main-btns-hide"
                  : "",
              ],
              attrs: { outlined: "" },
              on: { click: _vm.cancelBtnClicked },
            },
            [_vm._v(_vm._s(_vm.$t("Cancel")))]
          ),
          _c(
            "cs-btn",
            {
              attrs: {
                disabled: _vm.isCreateBtnDisabled,
                loading: _vm.dialogLoading,
              },
              on: { click: _vm.mainActionBtnClicked },
            },
            [_vm._v(_vm._s(_vm.getMainActionBtnText))]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }