var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      !_vm.showManagerRuleEditorDialog
        ? _c(
            "cs-simple-dialog",
            {
              attrs: {
                value: _vm.value,
                title: _vm.$t("Warehouse optimization rules"),
                persistent: "",
                maxWidth: _vm.maxWidth,
              },
              on: { input: _vm.closeBtnClicked },
            },
            [
              _c(
                "div",
                [
                  _c("v-text-field", {
                    staticClass:
                      "pt-0 mt-0 manager-rules-summary-dialog-text-field",
                    attrs: {
                      dense: "",
                      "append-icon": "mdi-magnify",
                      label: "",
                      "single-line": "",
                      "hide-details": "",
                    },
                    model: {
                      value: _vm.searchText,
                      callback: function ($$v) {
                        _vm.searchText = $$v
                      },
                      expression: "searchText",
                    },
                  }),
                ],
                1
              ),
              _c("v-data-table", {
                attrs: {
                  "filter-mode": "union",
                  height: 250,
                  headers: _vm.headers,
                  items: _vm.items,
                  "custom-filter": _vm.customFilter,
                  search: _vm.searchText,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: `item.rule`,
                      fn: function ({ item }) {
                        return [_vm._v(_vm._s(item.rule))]
                      },
                    },
                    {
                      key: `item.parameter`,
                      fn: function ({ item }) {
                        return [
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                bottom: "",
                                disabled: !_vm.isParameterTextCropped(
                                  item.parameter
                                ),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "div",
                                          _vm._g(
                                            _vm._b({}, "div", attrs, false),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.cropParameterText(
                                                  item.parameter
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [_c("span", [_vm._v(_vm._s(item.parameter))])]
                          ),
                        ]
                      },
                    },
                    {
                      key: `item.actions`,
                      fn: function ({ item }) {
                        return [
                          _vm._v(_vm._s(item.actions) + " "),
                          _c(
                            "cs-flex",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickOpenRuleAsReadOnly(
                                        item.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "grey darken-1" } },
                                    [_vm._v("mdi-eye")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickEditRule(item.id)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "grey darken-1" } },
                                    [_vm._v("mdi-pencil")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary", icon: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleClickDeleteRuleActionBtn(
                                        item.id
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "grey darken-1" } },
                                    [_vm._v("mdi-delete")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: `footer.prepend`,
                      fn: function () {
                        return [
                          _c(
                            "cs-btn",
                            { on: { click: _vm.handleClickCreateNewRule } },
                            [
                              _c("v-icon", [_vm._v("mdi-plus")]),
                              _vm._v(_vm._s(_vm.$t("Create new"))),
                            ],
                            1
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        : _c("manager-rule-editor-dialog", {
            attrs: {
              defaultManagerRuleDialogData: _vm.defaultManagerRuleDialogData,
            },
            on: {
              editManagerRuleActionBtnClicked:
                _vm.editManagerRuleActionBtnClicked,
              createManagerRuleActionBtnClicked:
                _vm.createManagerRuleActionBtnClicked,
            },
            model: {
              value: _vm.showManagerRuleEditorDialog,
              callback: function ($$v) {
                _vm.showManagerRuleEditorDialog = $$v
              },
              expression: "showManagerRuleEditorDialog",
            },
          }),
      _c("cs-warning-dialog", {
        attrs: {
          twoButton: "",
          width: "500",
          submitText: _vm.$t("Delete"),
          description: _vm.$t("Deletion cannot be undone"),
          title: _vm.$t("Are you sure you want to delete the rule?"),
        },
        on: { submit: _vm.handleClickDeleteRule },
        model: {
          value: _vm.showDeleteManagerRuleDialog,
          callback: function ($$v) {
            _vm.showDeleteManagerRuleDialog = $$v
          },
          expression: "showDeleteManagerRuleDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }