import { Color3 } from '@consteel/straw'
import Vue from 'vue'
import { SupplyOptimization } from '../graphql/graphql.types'
import { SupplyDefaultLength } from './types'
import { SupplyDefaultWaste } from './types/SupplyDefaultWaste.model'

const state = Vue.observable({
  defaultLengths: [] as SupplyDefaultLength[],
  defaultWaste: null as SupplyDefaultWaste | null,
  supplyOptimization: null as SupplyOptimization | null,
  supplyOptimizationSecondary: null as SupplyOptimization | null,
  supplyInstanceColors: [] as { id: string; color: Color3 }[],
  loading: false,
  progress: 0,
  error: '',
})

export const setSupplyDefaultLengths = (value: SupplyDefaultLength[]): void => {
  state.defaultLengths = value
}

export const setSupplyDefaultWaste = (value: SupplyDefaultWaste | null): void => {
  state.defaultWaste = value
}

export const increaseSupplyProgress = (): void => {
  state.progress += 1
}

export const decreaseSupplyProgress = (): void => {
  state.progress -= 1
}

export const getSupplyLoading = (): boolean => {
  return state.progress !== 0
}

export const setSupplyError = (errorMsg: string): void => {
  state.error = errorMsg
}

export const resetSupplyState = (): void => {
  state.loading = false
  state.error = ''
}

export const setSupplyOptimization = (value: SupplyOptimization | null): void => {
  state.supplyOptimization = value
}

export const setSupplyOptimizationSecondary = (value: SupplyOptimization | null): void => {
  state.supplyOptimizationSecondary = value
}

export const setSupplyInstanceColors = (value: { id: string; color: Color3 }[]): void => {
  state.supplyInstanceColors = value
}

export const supplyStore = state as Readonly<typeof state>
