
import Vue from 'vue'
import { CsSimpleDialog, CsBtn, CsFlex, CsWarningDialog } from '@consteel/csuetify'
import { DataTableHeader } from 'vuetify'
import {
  chainParameterNames,
  deleteManagerRule,
  onStockStore,
  OpenOptions,
  setDefaultManagerRuleDialogDataByRuleId,
  setOnStockModelManagerRules,
} from '@/modules/onstock'
import ManagerRuleEditorDialog from './ManagerRuleEditorDialog.vue'
import { DefaultManagerRuleData } from '../../../modules/onstock/types/OnStockManagerRuleInterfaces'
import { OnStockModel } from '@/modules/graphql/graphql.types'

type ManagerSetting = {
  id: number
  rule: string
  parameter: string
}

export default Vue.extend({
  name: 'ManagerRulesSummaryDialog',
  components: {
    CsSimpleDialog,
    CsBtn,
    ManagerRuleEditorDialog,
    CsFlex,
    CsWarningDialog,
  },
  props: {
    value: { type: Boolean, default: false },
    maxWidth: { type: Number, default: 650 },
    chainedParameterTextMaxLength: { type: Number, default: 35 },
  },
  data() {
    return {
      searchText: '',
      items: [] as ManagerSetting[],
      showManagerRuleEditorDialog: false,
      showDeleteManagerRuleDialog: false,
      deleteManagerRuleId: null as number | null,
      defaultManagerRuleDialogData: {
        openOption: OpenOptions.Write,
        managerRuleId: '',
        dialogName: '',
        selectedSectionFamily: { text: '', id: '' },
        parameterSettings: [],
        sectionTableData: [],
      } as DefaultManagerRuleData,
    }
  },
  mounted(): void {
    this.items = this.getInitManagerSettings()
  },
  computed: {
    onstockModel(): OnStockModel | null {
      return onStockStore.onStockModel
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: this.$t('Rule').toString(),
          align: 'start',
          sortable: false,
          value: 'rule',
          width: '30%',
        },
        {
          text: this.$t('Parameter').toString(),
          value: 'parameter',
          sortable: false,
          width: '50%',
        },
        {
          text: this.$t('Actions').toString(),
          value: 'actions',
          sortable: false,
          width: '20%',
        },
      ]
    },
  },
  methods: {
    isParameterTextCropped(chainedParameterNames: string): boolean {
      return chainedParameterNames.length > this.chainedParameterTextMaxLength
    },
    cropParameterText(chainedParameterNames: string): string {
      if (chainedParameterNames.length > this.chainedParameterTextMaxLength) {
        return chainedParameterNames.slice(0, this.chainedParameterTextMaxLength) + '...'
      }
      return chainedParameterNames
    },
    getInitManagerSettings(): ManagerSetting[] {
      if (!onStockStore.onStockModel?.managerRules) return []

      return onStockStore.onStockModel.managerRules.map((rule) => {
        return {
          id: rule.id,
          rule: rule.name,
          parameter: chainParameterNames(rule.managerRuleParameters, rule.materialStep),
        }
      })
    },
    customFilter(_, search: string | null, item): boolean {
      if (!search) return true
      return (
        item.rule.toLowerCase().includes(search) || item.parameter.toLowerCase().includes(search)
      )
    },
    closeBtnClicked(): void {
      this.$emit('input', false)
    },
    async handleClickOpenRuleAsReadOnly(itemId: number): Promise<void> {
      this.defaultManagerRuleDialogData = await setDefaultManagerRuleDialogDataByRuleId(
        OpenOptions.ReadOnly,
        itemId
      )
      this.showManagerRuleEditorDialog = true
    },
    async handleClickEditRule(itemId: number): Promise<void> {
      this.defaultManagerRuleDialogData = await setDefaultManagerRuleDialogDataByRuleId(
        OpenOptions.Edit,
        itemId
      )
      this.showManagerRuleEditorDialog = true
    },
    handleClickDeleteRuleActionBtn(managerItemId: number): void {
      this.showDeleteManagerRuleDialog = true
      this.deleteManagerRuleId = managerItemId
    },
    async handleClickDeleteRule(): Promise<void> {
      this.showDeleteManagerRuleDialog = false
      if (!onStockStore.project || !onStockStore.onStockModel) {
        console.warn('onstock project or model is null')
        return
      }

      if (!this.deleteManagerRuleId) return

      await deleteManagerRule(
        onStockStore.project.id.toString(),
        onStockStore.onStockModel.id.toString(),
        this.deleteManagerRuleId.toString()
      )

      const managerRules = onStockStore.onStockModel.managerRules.filter((rule) => {
        if (rule.id !== this.deleteManagerRuleId) return rule
      })
      setOnStockModelManagerRules(managerRules)
      this.items = this.getInitManagerSettings()
      this.deleteManagerRuleId = null
    },
    async handleClickCreateNewRule(): Promise<void> {
      this.defaultManagerRuleDialogData = await setDefaultManagerRuleDialogDataByRuleId(
        OpenOptions.Write
      )
      this.showManagerRuleEditorDialog = true
    },
    editManagerRuleActionBtnClicked(): void {
      this.items = this.getInitManagerSettings()
    },
    createManagerRuleActionBtnClicked(): void {
      this.items = this.getInitManagerSettings()
    },
  },
  watch: {
    onstockModel(): void {
      this.items = this.getInitManagerSettings()
    },
  },
})
